<template>
    <i :class="[
      'icon',
      `fa-${icon}`,
      fixedWidth ? 'fa-fw' : null,
      pack,
      size ? `fa-${size}` : null,
      ]" />
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Icon',
  props: {
    fixedWidth: {
      type: Boolean,
    },
    icon: {
      required: true,
      type: String,
    },
    pack: {
      default: 'fal',
      required: false,
      type: String,
    },
    size: {
      default: '',
      required: false,
      type: String,
    },
  },
});
</script>

<style scoped lang="scss">

</style>

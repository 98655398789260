<template>
    <nav
        class="fixed top-0 w-full bg-neutral-200 dark:bg-black flex items-center justify-between p-6 md:p-3 border-b border-b-neutral-500">

        <div class="flex justify-start">
            <a href="/">
                <Logo/>
            </a>
        </div>

        <div class="sm:flex flex-1 justify-end gap-x-4 hidden">
            <a :href="hrefs.pricing" class="dark:text-white py-2 px-3 font-bold">Pricing</a>
            <a :href="hrefs.login" class="dark:text-white py-2 px-3 font-bold">Log in</a>
            <a :href="hrefs.signUp" class="dark:text-white py-2 px-3 font-bold bg-brand-primary-400 rounded">7 Day Free Trial</a>
        </div>

        <div class="visible sm:hidden">
            <div @click="handleMobileMenuClick">
                <Icon class="text-lg dark:text-white"
                      icon="bars"
                      style="font-size: 1.7em;"
                ></Icon>
            </div>
        </div>

        <div class="mobile-menu fixed inset-0 bg-white dark:bg-black z-10 transition-all sm:hidden" :class="[
            isOpen ? 'translate-y-0' : 'translate-y-full',
        ]">

            <div class="pt-6 px-6">
                <div class="flex items-center justify-between mb-4">
                    <Logo/>
                    <div @click="handleMobileMenuClick">
                        <Icon icon="times" size="2x" class="dark:text-white"/>
                    </div>
                </div>
            </div>

            <div class="px-6 pb-6">

                <div class="menu py-2">

                    <div class="cursor-pointer flex justify-between py-2 uppercase text-sm">
                        <span class="dark:text-neutral-400">Welcome</span>
                    </div>

                    <div style="font-size: 1.2em;">

                        <a :href="hrefs.pricing" class="flex items-center rounded my-2 py-1">
                            <Icon icon="dollar" class="mr-3" fixed-width/> Pricing
                        </a>

                        <a :href="hrefs.login" class="flex items-center rounded my-2 py-1">
                            <Icon icon="lock" class="mr-3" fixed-width/> Log In
                        </a>

                        <a :href="hrefs.signUp" class="flex items-center rounded my-2 py-1">
                            <Icon icon="circle-plus" class="mr-3" fixed-width/> Free Trial
                        </a>

                    </div>

                </div>

            </div>

        </div>

    </nav>
</template>

<script>
import {h} from 'vue';

export default {
    name: 'Navbar',
    data() {
        return {
            isOpen: false,
            hrefs: {
                login: `${import.meta.env.VITE_VUE_APP_URL}/auth/login`,
                pricing: '/pricing',
                signUp: `${import.meta.env.VITE_VUE_APP_URL}/auth/sign-up`,
            },
        };
    },
    methods: {
        h,
        handleMobileMenuClick() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style scoped lang="scss">

</style>

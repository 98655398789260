<template>
    <div class="text-2xl font-semibold dark:text-white">
        <slot />
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Heading'
});
</script>

<style scoped lang="scss">
</style>

import './bootstrap.js';
import LogRocket from 'logrocket';

import { createApp } from 'vue';

import Button from './components/Button.vue';
import Heading from './components/Heading.vue';
import Icon from './components/Icon.vue';
import Logo from './components/Logo.vue';
import Navbar from './components/Navbar.vue';
import Page from './components/Page.vue';

import Home from './views/Home.vue';
import Pricing from './views/Pricing.vue';


const app = createApp({});

const rootHostname = import.meta.env.VITE_URL.replace('https://', '');

if (import.meta.env.VITE_APP_ENV !== 'local') {
    LogRocket.init(import.meta.env.VITE_LOG_ROCKET_APP_ID, {
        rootHostname,
    });
}

app.component('Button', Button);
app.component('Heading', Heading);
app.component('Icon', Icon);
app.component('Logo', Logo);
app.component('Navbar', Navbar);
app.component('Page', Page);

app.component('Home', Home);
app.component('Pricing', Pricing);

app.mount('#app');

<template>
    <div class="button cursor-pointer text-center font-semibold rounded border"
         :class="[

              block ? 'block' : 'inline-block',

              primary ? 'border-brand-primary-400' : null,
              primary && !outlined ? 'bg-brand-primary-400 hover:bg-brand-primary-300' : null,
              primary && outlined ? 'hover:border-brand-primary-300' : null,

              secondary ? 'text-neutral-900 dark:text-white border-neutral-300 dark:border-neutral-500' : null,
              secondary && !outlined ? 'bg-neutral-300 hover:bg-neutral-200 dark:bg-neutral-500 dark:hover:bg-neutral-400' : null,
              secondary && outlined ? 'hover:border-neutral-200 dark:hover:border-neutral-400' : null,

              warning ? 'border-yellow-500' : null,
              warning && !outlined ? 'bg-yellow-500 hover:bg-yellow-400' : null,
              warning && outlined ? 'hover:border-yellow-400' : null,

              danger ? 'border-red-800' : null,
              danger && !outlined ? 'text-white bg-red-800 hover:bg-red-700' : null,
              danger && outlined ? 'text-red-800 hover:border-red-700' : null,

              !primary && !secondary && !warning && !danger ? 'border-transparent' : null,

              size === 'xs' ? 'px-1.5 py-0.5' : null,
              size === 'sm' ? 'px-3 py-1' : null,
              size === 'md' ? 'px-5 py-1.5' : null,
              size === 'lg' ? 'px-7 py-2' : null,
         ]"
    >
        <div v-if="loading" class="px-4">
            <Icon class="fa-spin" :class="[
          primary ? 'text-white' : null,
        ]" icon="loader"/>
        </div>
        <slot v-else />
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Button',
  props: {
    block: {
      default: false,
      type: Boolean,
    },
    danger: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    outlined: {
      default: false,
      type: Boolean,
    },
    primary: {
      default: false,
      type: Boolean,
    },
    secondary: {
      default: false,
      type: Boolean,
    },
    size: {
      default: 'md',
      type: String,
    },
    warning: {
      default: false,
      type: Boolean,
    },
  },
})
</script>

<style scoped lang="scss">

</style>

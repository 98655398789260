<template>
    <div class="mx-auto max-w-sm px-6 h-full">

        <div class="text-2xl text-center mb-6">
            Possibly the best<br />
            band-based<br />
            workout app<br />
            on the entire planet.
        </div>

        <Button primary block @click="handleSignUpClick">
            Get Started
        </Button>

    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: 'Home',
    methods: {
        handleSignUpClick() {
            window.location = `${import.meta.env.VITE_VUE_APP_URL}/auth/sign-up`;
        },
    }
})
</script>

<style scoped lang="scss">

</style>

<template>

    <div class="flex items-center justify-between mb-6 flex-col sm:flex-row">

        <div class="mb-3 sm:mb-0">Billed Every</div>

        <div class="flex cursor-pointer">
            <div class="px-2 py-1 rounded-tl rounded-bl" :class="[interval === 'year' && frequency === 1 ? 'bg-brand-primary-400' : 'bg-neutral-400']" @click="interval = 'year'; frequency = 1;">
                Year
            </div>
            <div class="px-2 py-1" :class="[interval === 'month' && frequency === 6 ? 'bg-brand-primary-400' : 'bg-neutral-400']" @click="interval = 'month'; frequency = 6;">
                6 Months
            </div>
            <div class="px-2 py-1 rounded-tr rounded-br" :class="[interval === 'month' && frequency === 1 ? 'bg-brand-primary-400' : 'bg-neutral-400']" @click="interval = 'month'; frequency = 1;">
                Month
            </div>
        </div>

    </div>

    <div class="text-center text-4xl mb-6">
        {{ proPrice }}
    </div>

    <div class="text-center font-bold text-2xl p-2 border-b border-brand-primary-400">
        Features
    </div>

    <table class="w-full my-3">

        <template v-for="feature in features">

            <tr class="text-sm sm:text-base">
                <td class="w-1/2 py-2">{{feature.name}}</td>
                <td class="w-1/4 py-2 text-right">
                    <template v-if="feature.type === String">
                        {{feature.pro}}
                    </template>
                    <template v-else-if="feature.type === Boolean">
                        <Icon v-if="feature.pro" icon="check" pack="fas" class="text-lg text-brand-primary-400" />
                    </template>
                </td>
            </tr>

        </template>

    </table>

    <div class="text-center mb-6">
        More features in the works...
    </div>

    <div class="mt-10">
        <Button block primary @click="handleSignUpClick">
            Start 7 Day Free Trial
        </Button>
    </div>


</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: 'Pricing',
    computed: {
        proPrice() {
            if (this.interval === 'month' && this.frequency === 6) {
                return '$30/6 months';
            } else if (this.interval === 'month' && this.frequency === 1) {
                return '$5/month';
            } else if (this.interval === 'year') {
                return '$50/year';
            }
        },
    },
    data() {
        return {
            interval: 'year',
            frequency: 1,
            features: [
                {
                    name: 'Workout Log',
                    type: Boolean,
                    basic: true,
                    pro: true,
                },
                {
                    name: 'Body Log',
                    type: Boolean,
                    basic: true,
                    pro: true,
                },
                {
                    name: 'Custom Logs',
                    type: Boolean,
                    basic: false,
                    pro: true,
                },
                {
                    name: 'Analytics',
                    type: Boolean,
                    basic: false,
                    pro: true,
                },
                {
                    name: 'Progress Photos',
                    type: Boolean,
                    basic: false,
                    pro: true,
                },
            ],
        };
    },
    methods: {
        handleSignUpClick() {
          window.location = `${import.meta.env.VITE_VUE_APP_URL}/auth/sign-up`;
        },
    }
})
</script>

<style scoped lang="scss">

</style>
